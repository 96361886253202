$body-background-color: $black-mcs;
$sidebar-color: $blackgray-mcs;
$border-color: $blackgray-mcs;

$body-heading-color: #ffffff;
$body-text-color: #cacaca;
$nav-child-link-color: $white;
$search-result-preview-color: #9664F9;

$link-color: #9664F9;
$btn-primary-color: $blue-200;
$base-button-color: $grey-dk-250;

$code-background-color: #14072c;
$search-background-color: #14072c;
$table-background-color: $grey-dk-250;
$feedback-color: darken($sidebar-color, 3%);
