//
// The basic two column layout
//

.side-bar {
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  background-color: $sidebar-color;

  @include mq(md) {
    flex-wrap: nowrap;
    position: fixed;
    width: $nav-width-md;
    height: 100%;
    flex-direction: column;
    border-right: $border $border-color;
    align-items: flex-end;
  }

  @include mq(lg) {
    width: calc((100% - #{$nav-width + $content-width}) / 2 + #{$nav-width});
    min-width: $nav-width;
  }
}

.main {
  @include mq(md) {
    position: relative;
    max-width: $content-width;
    margin-left: $nav-width-md;
  }

  @include mq(lg) {
    margin-left: calc(
      (100% - #{$nav-width + $content-width}) / 2 + #{$nav-width}
    );
  }
}

.main-content-wrap {
  @include container;
  padding-top: $gutter-spacing-sm;
  padding-bottom: $gutter-spacing-sm;

  @include mq(md) {
    padding-top: $gutter-spacing;
    padding-bottom: $gutter-spacing;
  }
}

.main-header {
  z-index: 0;
  display: none;
  background-color: $sidebar-color;

  @include mq(md) {
    display: flex;
    justify-content: space-between;
    height: $header-height;
    background-color: $body-background-color;
    border-bottom: $border $border-color;
  }

  &.nav-open {
    display: block;

    @include mq(md) {
      display: flex;
    }
  }
}

.site-nav,
.site-header,
.site-footer {
  width: 100%;

  @include mq(lg) {
    width: $nav-width;
  }
}

.site-nav {
  display: none;

  &.nav-open {
    display: block;
  }

  @include mq(md) {
    display: block;
    padding-top: $sp-8;
    padding-bottom: $gutter-spacing-sm;
    overflow-y: auto;
    flex: 1 1 auto;
  }
}

.site-header {
  display: flex;
  min-height: $header-height;
  align-items: center;

  @include mq(md) {
    height: $header-height;
    max-height: $header-height;
    border-bottom: $border $border-color;
  }
}

.site-title {
  @include container;
  flex-grow: 1;
  display: flex;
  height: 100%;
  align-items: center;
  padding-top: $sp-3;
  padding-bottom: $sp-3;
  color: $body-heading-color;
  @include fs-6;

  @include mq(md) {
    padding-top: $sp-2;
    padding-bottom: $sp-2;
  }
}

@if variable-exists(logo) {
  .site-logo {
    width: 100%;
    height: 100%;
    background-image: url($logo);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
  }
}

.site-button {
  display: flex;
  height: 100%;
  padding: $gutter-spacing-sm;
  align-items: center;
}

@include mq(md) {
  .site-header .site-button {
    display: none;
  }
}

.site-title:hover {
  background-image: linear-gradient(
    -90deg,
    rgba($feedback-color, 1) 0%,
    rgba($feedback-color, 0.8) 80%,
    rgba($feedback-color, 0) 100%
  );
}

.site-button:hover {
  background-image: linear-gradient(
    -90deg,
    rgba($feedback-color, 1) 0%,
    rgba($feedback-color, 0.8) 100%
  );
}

// stylelint-disable selector-max-type

body {
  position: relative;
  padding-bottom: $sp-10;
  overflow-y: scroll;

  @include mq(md) {
    position: static;
    padding-bottom: 0;
  }
}

// stylelint-enable selector-max-type

.site-footer {
  @include container;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: $sp-4;
  padding-bottom: $sp-4;
  color: $grey-dk-000;
  @include fs-2;

  @include mq(md) {
    position: static;
    justify-self: end;
  }
}

.icon {
  width: $sp-5;
  height: $sp-5;
  color: $link-color;
}


// 🟧 Grid Card Images, 

.column_grid {
  float: left;
  width: 33%;
  padding: 15px;
}

// 🟧 for the 2 profile images in ABOUT 

.column_profile {
  float: left;
  width: 30%;
  padding: 5px;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

// 🟧 All image, rounded edge

img {
  border-radius: 3%
}

//🟧 Select image, no rounding

.straight_edge img {
  border-radius: 0%
}

// 🟢 import gist theme

/* https://github.com/lonekorean/gist-syntax-themes */
@import url('https://cdn.rawgit.com/lonekorean/gist-syntax-themes/d49b91b3/stylesheets/one-dark.css');

@import url('https://fonts.googleapis.com/css?family=Open+Sans');
body {
  margin: 0px;
  font: 16px 'Open Sans', sans-serif;
}



// 🟢 ---- video grid view
.videos-grid {
  display:grid;
  grid-template-columns:1fr 1fr;
  grid-column-gap: 30px;
}

.videos-grid-video {
}

.videos-grid-video > iframe {
  height:278px;
}